import '../scss/main.scss';

const navSlide = () => {

  const burger = document.querySelector('.burger');
  const nav = document.querySelector('.nav-links');
  const navLinks = document.querySelectorAll('.nav-links li');

  burger. addEventListener('click', () => {

    // Toggle NAV
    nav.classList.toggle('active');

    // Animate Links
    navLinks.forEach((link, index) => {
      if ( link.style.animation ) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 3 + 0.5}s`;
      }
    });

    // Burger animation
    burger.classList.toggle('toggle');

  })
}

navSlide();